<template>
  <div id="app">
    <div>
      <h4>答题情况</h4>
      <el-divider content-position="left"></el-divider>
    </div>
    <div class="bigDiv">
      <el-row :gutter="20">
        <el-col :xs="18" :sm="10" :md="6" :lg="4">
          <el-form label-width="50px">
            <el-form-item label="姓名:">
              <el-input v-model="realname" placeholder="请输入姓名" clearable></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="18" :sm="10" :md="6" :lg="4">
          <el-form label-width="50px">
            <el-form-item label="学号:">
              <el-input v-model="username" placeholder="请输入学号" clearable></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="18" :sm="10" :md="8" :lg="4">
          <el-form label-width="80px">
            <el-form-item label="答题状态:">
              <el-select v-model="answerStatus" placeholder="请选择答题状态" clearable>
                <el-option v-for="item in dstatus" :key="item.answerStatus" :label="item.name"
                  :value="item.answerStatus">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="18" :sm="10" :md="8" :lg="5">
          <el-form label-width="110px">
            <el-form-item label="科目名称:">
              <el-select v-model="cid" placeholder="请选择科目名称">
                <el-option v-for="item in cname" :key="item.cid" :label="item.cname"
                  :value="item.cid">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="18" :sm="10" :md="8" :lg="4">
          <el-form label-width="80px">
            <el-form-item label="任务名称:">
              <el-select v-model="sc_task" placeholder="请选择任务名称" clearable>
                <el-option v-for="item in tname" :key="item.sc_task" :label="item.sc_task"
                  :value="item.sc_task">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="18" :sm="6" :md="4" :lg="1">
          <el-button type="primary" @click="goSearch">查询</el-button>
        </el-col>
      </el-row>

      <el-table :data="lists" style="width: 100%" :stripe="true" :border="true" height="688"
        :header-cell-style="{background:'#eef1f6',color:'#606266'}">
        <el-table-column prop="sc_realname" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="sc_username" label="学号" width="180">
        </el-table-column>
        <el-table-column prop="c_name" label="科目名称">
        </el-table-column>
        <el-table-column prop="sc_task" label="任务名称">
        </el-table-column>
        <el-table-column prop="sc_answer_status" label="答题状态">
          <template slot-scope="scope">
            <el-tag type="warning" v-show="scope.row.sc_answer_status === 0">未答题</el-tag>
            <el-tag type="success" v-show="scope.row.sc_answer_status === 1">答题中</el-tag>
            <el-tag type="" v-show="scope.row.sc_answer_status === 2">答题成功</el-tag>
            <el-tag type="danger" v-show="scope.row.sc_answer_status === -1">答题失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="sc_start_time" label="答题开始时间">
          <template slot-scope="scope">
            {{parseTime(scope.row.sc_start_time,'{y}-{m}-{d} {h}:{i}:{s}')}}
          </template>
        </el-table-column>
        <el-table-column prop="sc_submit_time" label="答题结束时间">
          <template slot-scope="scope">
            {{parseTime(scope.row.sc_submit_time,'{y}-{m}-{d} {h}:{i}:{s}')}}
          </template>
        </el-table-column>
        <div slot="empty">
          <p>
            <img :src="imgUrl" />
          </p>
        </div>
      </el-table>
      <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
        @size-change="handleSizeChange" :total="allLength || 0" align="right">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
export default {
  data () {
    return {
      realname: '', // 姓名
      username: '', // 学号
      cname: [], // 科目名称
      cid: '', // 选中的科目名称
      tname: [], // 根据科目名称查询任务
      sc_task: '', // 选中的任务名称
      dstatus: [{
        name: '未答题',
        answerStatus: 0
      }, {
        name: '答题中',
        answerStatus: 1
      }, {
        name: '答题成功',
        answerStatus: 2
      }, {
        name: '答题失败',
        answerStatus: -1
      }], // 答题状态
      answerStatus: '', // 选中的答题状态
      lists: [], // 获取到的数据
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png') // 图片地址
    }
  },
  mounted () {
    this.allCourseName()
    this.goSearch()
  },
  methods: {
    // 查询科目名称
    allCourseName: function () {
      this.axios.get('http://192.168.124.102:8866/questionBank/getAllCourseName').then((response) => {
        this.cname = response.data
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询数据
    goSearch: function () {
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      if (this.answerStatus || this.answerStatus === 0) data.answerStatus = this.answerStatus
      if (this.realname) data.realname = this.realname
      if (this.sc_task) data.task = this.sc_task
      if (this.username) data.username = this.username
      if (this.cid || this.cid === 0) data.courseId = this.cid
      this.axios({
        url: 'http://192.168.124.102:8866/questionBank/queryStuCoursePage',
        method: 'post',
        data: JSON.stringify(data),
        headers: { 'content-Type': 'application/json' }
      }).then((response) => {
        this.lists = response.data.data || []
        this.allLength = Number(response.data.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  watch: {
    // 监听科目名称下拉选框切换时发送请求获取到任务名称,并且触发查询操作
    cid (val, oldVal) {
      this.sc_task = ''
      this.axios.get('http://192.168.124.102:8866/questionBank/getTaskByCourse', {
        params: {
          id: val
        }
      }).then((response) => {
        this.tname = response.data.data
      }).catch((response) => {
        console.log(response)
      })
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听任务名称改变时触发查询操作
    sc_task (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-pagination {
  margin-top: 20px;
  margin-bottom: 30px;
}
</style>
